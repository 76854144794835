












































































import { Component } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import { ApplicationData } from '@/store/student/types'
import MediaUploader from '@/components/common/MediaUploader.vue'
import CustomSelect from '@/components/common/CustomSelect.vue'
import Unescape from '@/common/unescape'
import {
  GET_APPLICATION_DATA_EDIT,
  SET_APPLICATION_DATA
} from '@/store/student/actions'
import SubmitSuccess from '@/components/forms/SubmitSuccess.vue'
import { FileDto, FileRequest, GenericRequest, SimpleServerResponse, User } from '@/store/types'
import { Form, KeyValue, MediaFile } from '@/store/commonFormInput/types'
import { parseLocalDateToDate } from '@/common/globals'
import DatePicker from 'vue2-datepicker'
import BaseForm from '@/components/forms/BaseForm.vue'
import InputTypes from '@/common/formTypes'
import Rights, { STUDENT_OWN_VIEW } from '@/common/rights'
import PhotoMutation from '@/common/getPhotoMutation'
import { DELETE_PHOTO, GET_PHOTO, SAVE_PHOTO, SET_ACTIVATION_STATUS_OWN_PROFILE } from '@/store/user/actions.type'
import { hasRight } from '@/utils/rights'
import Enums from '@/common/Enums'
import userDomain from '@/store/common/userDomain'
import PreviewHandler from '@/components/common/PreviewHandler.vue'
import MetaComponent from '@/common/MetaComponent.vue'

  @Component({
    components: { PreviewHandler, BaseForm, CustomSelect, MediaUploader, SubmitSuccess, DatePicker }
  })
export default class ApplicationDataEditor extends MetaComponent {
    @State('studentState.baseInformation') baseData: ApplicationData

    @Action(GET_APPLICATION_DATA_EDIT)
    public getApplicationInformation: () => Promise<SimpleServerResponse>

    @Action(SET_APPLICATION_DATA)
    public setApplicationInformation: (data: ApplicationData) => Promise<SimpleServerResponse>

    @Action(SAVE_PHOTO)
    public savePhoto: (data: FileRequest) => Promise<SimpleServerResponse>

    @Action(GET_PHOTO)
    public getPhoto: (role: string) => Promise<FileDto>

    @Action(DELETE_PHOTO)
    public deletePhoto: (role: string) => Promise<SimpleServerResponse>

    @Action(SET_ACTIVATION_STATUS_OWN_PROFILE)
    public setActivationStatusForOwnProfile: (data: GenericRequest) => Promise<SimpleServerResponse>

    @Getter('userGetter') userGetter: User
    @Getter('photoFileNameGetter') persistedPhoto: string | undefined

    video: MediaFile = {}

    applicationData: ApplicationData = {
      city: '',
      dateOfBirth: undefined,
      description: '',
      jobStatus: 'SEARCH',
      mobile: '',
      number: '',
      phone: '',
      postalCode: '',
      socialMedia: '',
      street: '',
      supplement: '',
      website: 'https://',
      photo: {},
      video: {},
      activationStatus: ''
    }

    submitSuccess = false
    gotResponse = false
    feedbackHeadline = ''
    feedbackInfo = ''
    photoSaved = false

    get employmentStates (): KeyValue[] {
      return Enums.EMPLOYMENT_STATES.map(key => {
        return {
          value: key,
          text: this.$i18n.t('student.my-data.EMPLOYMENT_STATES.' + key).toString()
        }
      })
    }

    get fields (): Form[] {
      return [
        {
          id: 'description',
          key: 'description',
          type: InputTypes.TEXTAREA,
          label: this.$i18n.t('student.my-data.portrait').toString(),
          md: 12
        },
        {
          id: 'photo',
          key: 'photo',
          type: InputTypes.PHOTO,
          label: this.$i18n.t('student.my-data.photo').toString()
        },
        {
          id: 'video',
          key: 'video',
          type: InputTypes.DUMMY,
          label: this.$i18n.t('student.my-data.video').toString() + ' blockiert, da Videos bisher nicht verarbeitet werden'
        },
        {
          id: 'jobStatus',
          key: 'jobStatus',
          label: this.$i18n.t('student.my-data.employmentState').toString(),
          type: InputTypes.RADIO,
          options: this.employmentStates
        },
        {
          id: 'dummy',
          key: 'dummy',
          type: InputTypes.DUMMY
        },
        {
          id: 'supplement',
          key: 'supplement',
          type: InputTypes.TEXT,
          label: this.$i18n.t('personal-information.addressAppendix').toString()
        },
        {
          id: 'dummy',
          key: 'dummy',
          type: InputTypes.DUMMY
        },
        {
          id: 'street',
          key: 'street',
          type: InputTypes.TEXT,
          label: this.$i18n.t('personal-information.street').toString()
        },
        {
          id: 'number',
          key: 'number',
          type: InputTypes.TEXT,
          label: this.$i18n.t('personal-information.number').toString()
        },
        {
          id: 'postalCode',
          key: 'postalCode',
          type: InputTypes.TEXT,
          label: this.$i18n.t('personal-information.zipCode').toString()
        },
        {
          id: 'city',
          key: 'city',
          type: InputTypes.TEXT,
          label: this.$i18n.t('personal-information.city').toString()
        }
      ]
    }

    get contactFields (): Form[] {
      return [
        {
          id: 'phone',
          key: 'phone',
          type: InputTypes.PHONE,
          label: this.$i18n.t('personal-information.phone').toString()
        },
        {
          id: 'mobile',
          key: 'mobile',
          type: InputTypes.PHONE,
          label: this.$i18n.t('personal-information.mobile').toString()
        },
        {
          id: 'website',
          key: 'website',
          type: InputTypes.TEXT,
          label: this.$i18n.t('personal-information.website').toString()
        },
        {
          id: 'online',
          key: 'socialMedia',
          type: InputTypes.TEXT,
          label: this.$i18n.t('student.my-data.social-media').toString()
        },
        {
          id: 'dateOfBirth',
          key: 'dateOfBirth',
          type: InputTypes.DATE,
          label: this.$i18n.t('personal-information.dateOfBirth').toString(),
          disabledDates: { start: undefined, end: new Date() }
        }
      ]
    }

    get writeable (): boolean {
      return hasRight(Rights.STUDENT_OWN_PROFILE_READ)
    }

    get readonly (): boolean {
      return hasRight(Rights.STUDENT_OWN_PROFILE_READ)
    }

    get hasPreviewRight (): boolean {
      return hasRight(STUDENT_OWN_VIEW)
    }

    created (): void {
      this.$root.$emit('load')
      this.getApplicationInformation().then((data: SimpleServerResponse) => {
        this.applicationData = data.content
        this.applicationData.website = data.content.website ? data.content.website : 'https://'
        this.applicationData.dateOfBirth = parseLocalDateToDate(data.content.dateOfBirth)
        this.$set(this.applicationData, 'video', { })
        this.getPhoto(userDomain.STUDENT).then(data => {
          this.$set(this.applicationData, 'photo', { file: data.base64Content })
        }).catch(() => {
          this.$set(this.applicationData, 'photo', { })
        })
      }).finally(() => {
        this.$root.$emit('end-load')
      })

      const root = document.getElementById('application-data-editor')
      if (root !== null) {
        Unescape.setSoftHyphensInLabels(root)
      }
    }

    clearMedia (id: string): void {
      this.$set(this.applicationData, id, { })
    }

    save (): Promise<void> {
      let savedPhotoSuccessfully = false
      let savedProfileSuccessfully = false
      this.$root.$emit('load')
      return this.setApplicationInformation(this.applicationData)
        .then(() => {
          savedProfileSuccessfully = true
          this.photoSaved = true
        })
        .catch(() => {
          savedProfileSuccessfully = false
          this.photoSaved = false
        })
        .finally(() => {
          return this.callSavePhoto()
            .then(() => {
              savedPhotoSuccessfully = true
            })
            .catch(() => {
              savedPhotoSuccessfully = false
            }).finally(() => {
              this.submitSuccess = savedPhotoSuccessfully && savedProfileSuccessfully
              this.$root.$emit(
                'alert',
                this.$i18n.t('student.my-data.photo' + this.submitSuccess ? 'info.save-success' : 'info.save-failed', {
                  what: this.$i18n.t(this.submitSuccess ? 'main-menu-student.application-data' : savedProfileSuccessfully ? 'student.my-data.photo' : 'main-menu-student.application-data')
                }).toString(),
                '',
                !this.submitSuccess
              )
              this.gotResponse = true
              this.$root.$emit('end-load')
            })
        })
    }

    callDeletePhoto (photoMutation: string): Promise<SimpleServerResponse> {
      if (photoMutation === 'CHANGED' || photoMutation === 'DELETED') {
        return this.deletePhoto(userDomain.STUDENT)
      } else {
        return Promise.resolve({ message: 'ok', requestStatus: '200' }) as Promise<SimpleServerResponse>
      }
    }

    callSavePhoto (): Promise<SimpleServerResponse> {
      const photoMutation = PhotoMutation.getPhotoMutation(this.persistedPhoto, this.applicationData.photo)
      return this.callDeletePhoto(photoMutation).then(() => {
        if (photoMutation === 'CHANGED' || photoMutation === 'ADDED') {
          if (this.applicationData.photo && this.applicationData.photo.base64ImageData !== undefined && this.applicationData.photo.fileForUpload !== undefined) {
            const file: FileDto = {
              base64Content: this.applicationData.photo.base64ImageData,
              filename: this.applicationData.photo.fileForUpload.name
            }
            const dto: FileRequest = {
              role: userDomain.STUDENT,
              file: file
            }

            return this.savePhoto(dto)
          } else {
            return Promise.reject(Error('image not available'))
          }
        } else {
          return Promise.resolve({ message: 'ok', requestStatus: '200' }) as Promise<SimpleServerResponse>
        }
      }).catch((error) => {
        return Promise.reject(error) as Promise<SimpleServerResponse>
      }) as Promise<SimpleServerResponse>
    }

    public showPreview (): void {
      this.save()
      this.$nextTick(() => {
        const lang = this.$i18n.locale.toString()
        const routeData = this.$router.resolve({ name: 'talent-profile-preview', params: { id: this.userGetter.appUserId } })
        const target = window.location.origin + '/' + lang + routeData.href
        window.open(target, '_blank')
      })
    }

    activationButtonClick (): void {
      const requestData: GenericRequest = {
        query: ['', 'TALENT'],
        params: undefined
      }

      switch (this.applicationData.activationStatus) {
        case 'ACTIVE':
          requestData.query[0] = 'false'
          break
        case 'DEACTIVATED':
          requestData.query[0] = 'true'
          break
        case 'ACTIVATION_REQUESTED':
          requestData.query[0] = 'false'
          break
        default:
          requestData.query[0] = 'true'
      }

      this.$root.$emit('load')
      this.setActivationStatusForOwnProfile(requestData).then((data) => {
        this.applicationData.activationStatus = data.content
      }).catch(() => {
        this.$root.$emit('alert', this.$t('warning.error!').toString(), this.$t('warning.activation-failed').toString(), true)
      }).finally(() => {
        this.$root.$emit('end-load')
      })
    }
}
